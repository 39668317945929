import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';

import Image from "../../image"

const ImageListing = ({ Position, borderRadius, subTitles }) => {
  return (
    <section data-theme="white">
      <div className="container container--lg margin-top--lg margin-bottom--lg margin---none">
        <h2 className="margin-bottom--md flex flex--center">
          Detta får du utav utbildningen
        </h2>
        <div className="grid grid--gap-sm flex--center-x flex--center-y">
          <div className="col col--12 col--sm-6 ">
            <Image
              style={
                borderRadius && {
                  borderRadius: "16px!important",
                  widtdh: "100%",
                }
              }
              filename="section2.jpg"
              alt="section2"
              classname="col--lg-10"
            />
          </div>
          <div className="col col--12 col--sm-6">
            <div className="text--component text--left">
              {subTitles &&
                subTitles.map(subTitle => (
                  <div className="margin-bottom--md">
                    <div
                      className="flex flex--start "
                      style={{ alignItems: "center" }}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                      <h3>{subTitle.title}</h3>
                    </div>
                    <p className="margin-top--sm">{subTitle.text}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageListing
