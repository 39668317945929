import React from 'react';
import Layout from '../../components/layout';
import Segment from '../../components/segment';
import HeroLogoText from '../../components/sections/heroLogoText/heroLogoText';
import Seo from '../../components/seo';
import ImageIconText from '../../components/sections/imageIconText/imageIconText';
import CtaMail from '../../components/sections/ctaMail/ctaMail';
import ImageText from '../../components/sections/imageText/imageText';
import Testimonial from '../../components/sections/testimonial/testimonial';

const IndexPage = () => (
  <Layout
    backgroundColor="#172D47!important"
    textColor="#06E55C!important"
    headerWitdh="header"
    headerStyle="flex--space-between"
    showFooter
  >
    <Segment id="ezM1zWXFhQ0Lzjqy68oi4yaRFGE96phW" />
    <Seo title="Artroskörkortet" language="sv" />
    <HeroLogoText />
    <ImageIconText
      subTitles={[
        {
          title: 'Utökade kunskaper',
          text:
              'In interdum facilisis lectus sed ridiculus sed. Ac eget ornare pellentesque tortor. Nec nibh erat adipiscing elit. Mi eget orci potenti consequat viverra enim. A.',
        },
        {
          title: 'Mi ultricies fames adipiscing',
          text:
              'Nisl porta feugiat consequat, blandit purus quisque lacinia. Consectetur leo neque, velit egestas lectus auctor sed lacus. Ante ac non nullam congue turpis.',
        },
        {
          title: 'Diplom',
          text:
              'A nisl feugiat auctor varius mi. Euismod fames gravida semper urna molestie ornare. Morbi vel neque.',
        },
      ]}
    />
    <CtaMail dataTheme="float" />
    <ImageText
      imgSize="col--12 col--lg-6"
      Filename="img-treatment.png"
      borderShow="borderShow"
      alt="blue california"
      Position="rowRevers"
      title="Rätt behandling vid rätt tidpunkt för varje individ."
      text="Joint Academy gör det lätt för dig att följa nationella riktlinjer om artrosbehandling, vilket i sin tur förbättrar livskvaliteten för dina patienter."
    />
    <Testimonial
      renderBigTitle
      titleBigClassName=""
      BigTitle="En långsiktig lösning mot
kronisk ledvärk."
      renderTitle
      renderText
      title="Leif Dahlberg, CMO, explains Joint Academy"
      titleClassName="text--sm"
      videoSrcURL="https://player.vimeo.com/video/362961288?color=fff&title=0&byline=0&portrait=0"
      videoTitle="Leif Dahlberg, CMO, explains Joint Academy"
    />
  </Layout>
);

export default IndexPage;
