import React from "react"

const ctaMail = ({ dataTheme }) => {
  const onSubmit = event => {
    if (typeof window !== "undefined") {
      window.MERGE0(event)
    }
  }
  return (
    <section data-theme={dataTheme}>
      <div
        className="container container--lg margin-top--lg margin-bottom--lg"
        style={{ backgroundColor: "#edf4fc!important" }}
      >
        <div className="text--component margin-bottom--md text--center">
          <h3 className="margin-bottom--md">
            Ange din e-postadress för att ta del av utbildningen.
          </h3>

          <form
            action="https://jointacademy.us14.list-manage.com/subscribe/post"
            method="post"
            className="flex flex--center-y"
            onSubmit={onSubmit}
          >
            <input type="hidden" name="u" value="af6b2530d423199a95fe20a13" />
            <input type="hidden" name="id" value="b01c26e96f" />
            <input
              type="email"
              autoCapitalize="off"
              autoCorrect="off"
              name="MERGE0"
              id="MERGE0"
              className="margin-right--xs form-control form-control--md form-control--white text--left"
              placeholder="E-postadress"
              onFocus={e => (e.target.placeholder = "")}
              onBlur={e => (e.target.placeholder = "Din e-postadress")}
              required
            />
            <input
              type="checkbox"
              value="4"
              name="group[7651][4]"
              id="mce-group[7651]-7651-2"
              checked
            />
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_af6b2530d423199a95fe20a13_b01c26e96f"
                tabIndex="-1"
                value=""
              />
            </div>
            <div>
              <input
                type="submit"
                className="btn btn--primary btn--md btn--square flex--0"
                name="submit"
                value="Skicka"
              />
            </div>
          </form>
          {/* <form id="jaSMSForm" onSubmit={onSubmit}>
              <input
                id="jaSMSFormField"
                className="form-control form-control--md form-control--white text--left"
                type="tel"
                name="tel"
                autoComplete="off"
                placeholder="Patient phone number"
                required
              />
              <button
                id="jaSMSFormSubmit"
                type="submit"
                className="btn btn--primary btn--md"
                style={{ marginTop: "0" }}
              >
                Submit
              </button>
            </form> */}
        </div>
      </div>
    </section>
  )
}

export default ctaMail
