import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Image from "../../image"

import horizontal from "../../../assets/shape_border_horizontal.svg"
import vertical from "../../../assets/shape_border_vertical.svg"

const HeroLogoText = ({
  dataTheme,
  alt,

  title,
  titleClassName,
  text,
  textClassName,

  titleButtom,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "hero_referral.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = data.hero.childImageSharp.fluid

  return (
    <section data-theme={dataTheme} id="top" style={{ overflow: "visible" }}>
      <div className="container container--lg">
        <div className="grid flex--start">
          <BackgroundImage
            Tag="section"
            fluid={imageData}
            alt={alt}
            className="col col--12 col--sm-6 fullwidth--sm hero--cover"
          >
            <img alt="horizontLeft" className="sp hide--sm" src={horizontal} />
            <img alt="verticalLeft" className="sp display--sm" src={vertical} />
          </BackgroundImage>
          <div
            id="herotext"
            className="col col--12 col--sm-6 margin-top--lg margin-bottom--lg"
          >
            <div className={`col col--8 col--sm-6`}>
              <Image
                filename="joint-academy-logo@2x.png"
                alt={alt}
                classname=""
              />
            </div>
            <h1>Artroskörkortet</h1>
            <p>
              Artorskörkortet är en kort utbildning vars syfte är att förbättra
              kunskapen kring artros inom den medicinska gemenskapen och på så
              sätt kunna förse patienter med den bästa möjliga vården.
            </p>
            <h1 className={`margin-bottom--sm ${titleClassName}`}>{title}</h1>
            <p className={textClassName}>{text}</p>
            <div style={{ maxWidth: "350px" }}>
              <div className="flex--center">
                <p className="margin-bottom--sm text--sm"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroLogoText
